module.exports = [{
      plugin: require('../node_modules/gatsby-theme-shopify-manager/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"gachadays","accessToken":"5f74b9ad3db0d424812c03b5f823c50b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gachadays","short_name":"gachadays","description":"Gacha boosting and re-rolled accounts","lang":"en","display":"standalone","start_url":"/","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
